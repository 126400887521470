<template>
  <div class="home">
    <div class="title">
      <span class="opacity"></span><span></span>
      <p>{{ title }}</p>
      <span></span><span class="opacity"></span>
    </div>
    <div class="content">
      <div class="timePicker">
        <div class="btn" @click="prevDate">
          <img src="../assets/icon_l.png" />
          <p>前一日</p>
        </div>
        <img class="line" src="../assets/icon_line.png" />
        <div class="date" @click="timeClick">{{ date }}</div>
        <img class="line" src="../assets/icon_line.png" />
        <div class="btn" @click="nextDate">
          <p>后一日</p>
          <img src="../assets/icon_r.png" />
        </div>
      </div>
      <div class="tag" @click="ExportInfo">
        <p>导出excel</p>
      </div>
      <div class="listbox">
        <div class="search">
          <input
            type="text"
            v-model="sPatientName"
            placeholder="患者姓名查询"
          />
          <div class="btn" @click="searchList"></div>
        </div>
        <div class="list">
          <div class="th">
            <span>患者姓名</span>
            <span>磷(mg)</span>
            <span>蛋白(g)</span>
            <span>磷/蛋白(mg/g)</span>
            <span>食谱详情</span>
          </div>
          <template v-if="dataShow">
            <div class="td" v-for="(item, index) in list" :key="index">
              <span>{{ item.sPatientName }}</span>
              <span>{{ item.p }}</span>
              <span>{{ item.pro }}</span>
              <span>{{ item.p_pro }}</span>
              <span @click="patientInfo(item.sOpenId)">查看历史</span>
            </div>
          </template>
          <template v-if="!dataShow">
            <img src="../assets/not_data.png" class="not_data_img" />
            <P class="not_data_title">暂无数据</P>
          </template>
        </div>
        <pageNation
          v-if="totalPage !== ''"
          :key="date"
          :show-jumper="true"
          :total="totalPage"
          :currentPage="page"
          :pagerCount="5"
          @prev-click="prevClick"
          @next-click="nextClick"
          @prev-quick-click="prevQuickClick"
          @next-quick-click="nextQuickClick"
          @page-click="pageClick"
          @current-change="currentChange"
          @jumpe="pageJumpe"
        >
        </pageNation>
      </div>
    </div>
    <div class="footbox">
      <!-- <div class="btnbox"><img class="img" style="width:0.16rem;height:0.18rem" src="../assets/icon_home.png"><p>主页</p></div> -->
      <div class="btnbox">
        <img
          class="img"
          style="width: 0.38rem; height: 0.38rem"
          src="../assets/icon_home_select.png"
        />
      </div>
      <div class="btnbox" @click="pushMy">
        <img
          class="img"
          style="width: 0.18rem; height: 0.2rem"
          src="../assets/icon_my.png"
        />
        <p>我的</p>
      </div>
    </div>
    <!-- 弹框 -->
    <van-popup v-model:show="show">
      <div class="poput">
        <div class="box">
          <div class="boxtitle"><p>选择导出时间段</p></div>
          <div class="borderdiv">
            <div class="inputBox">
              <p>起始时间：</p>
              <input
                type="text"
                v-model="sStartTime"
                readonly
                @click="sTimePopup"
              />
            </div>
            <div class="inputBox">
              <p>结束时间：</p>
              <input
                type="text"
                v-model="sEndtime"
                readonly
                @click="sTimePopup"
              />
            </div>
            <div class="btnbox">
              <div class="queren" @click="queren">确认</div>
              <div class="quxiao" @click="quxiao">取消</div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model:show="excelShow">
      <div class="poput">
        <div class="box">
          <div class="boxtitle" style="width: 0.86rem"><p>导出excel</p></div>
          <div class="borderdiv">
            <!-- <p style="font-size: 0.12rem; color: #2ea7e0">{{ excelUrl }}</p> -->
            <p style="font-size: 0.14rem;line-height: 0.22rem;text-align: center;padding:0.15rem 0.2rem">
              点击下方复制链接按钮，到任意浏览器打开链接即可下载文件
            </p>
            <div class="btnbox">
              <div class="queren" @click="ctrlC">点击复制</div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
    <van-calendar
      v-model:show="dateShow"
      type="range"
      @confirm="datePopup"
      :show-confirm="false"
      :min-date="minDate"
      :max-date="maxDate"
    />
    <van-calendar v-model:show="timeShow" :show-confirm="false" :min-date="minDate" :max-date="maxDate" @confirm="timePopup"/>
  </div>
</template>

<script>
import { fun_date, getNowDay, getDateDifference } from "../util/util";
import { Toast } from "vant";
import { GetPaitentList, ExportInfo } from "../../src/api/doctorApi.js";
import pageNation from "../components/pageNation.vue";
export default {
  name: "patientList",
  data() {
    return {
      title: this.$route.meta.title,
      date: getNowDay(),
      dateIndex: 0,
      list: [],
      totalPage: "", //总页数
      pageIndex: 1, //当前页
      pageSize: 10, //每页多少条
      sPatientName: "",
      show: false,
      dataShow: false,
      dateShow: false,
      timeShow:false,
      sStartTime: fun_date(-1)[1],
      sEndtime: fun_date(0)[1],
      minDate: "",
      maxDate: "",
      excelShow: false,
      excelUrl: "",
    };
  },
  components: {
    pageNation,
  },
  beforeCreate() {
    document.title = this.$route.meta.title;
    if (window.localStorage.getItem("doctorId") == null) {
      Toast({
        message: "请重新登录",
        duration: 1500,
      });
      this.$router.push({
        path: `/login`,
        query: {},
      });
    }
  },
  created() {
    this.minDate = new Date(fun_date(-730)[1]);
    this.maxDate = new Date(fun_date(0)[1]);
  },
  computed: {},
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let params = {
        doctorId: window.localStorage.getItem("doctorId"),
        sDate: this.date,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        sPatientName: this.sPatientName,
      };
      GetPaitentList(params)
        .then((res) => {
          if (res.data.IsSuccess) {
            this.dataShow = true;
            // this.navItems = res.data.items.map(i => i.sHospitalName)
            this.totalPage = res.data.Data.pages;
            this.list = res.data.Data.items;
          } else {
            this.dataShow = false;
            this.list = "";
            Toast({
              message: res.data.Message,
              duration: 2000,
            });
          }
        })
        .catch((err) => {
          Toast({
            message: err,
            duration: 2000,
          });
        });
    },
    prevDate() {
      this.dateIndex = this.dateIndex - 1;
      this.date = fun_date(this.dateIndex)[1];
      this.pageIndex = 1;
      this.getList();
    },
    nextDate() {
      if (this.dateIndex >= 0) {
        Toast({
          message: "最多只能查询到当日患者信息",
          duration: 2500,
        });
      } else {
        this.dateIndex = this.dateIndex + 1;
        this.date = fun_date(this.dateIndex)[1];
        this.pageIndex = 1;
        this.getList();
      }
    },
    searchList() {
      this.getList();
    },
    patientInfo(sOpenId) {
      this.$router.push({
        path: `/patientDiet`,
        query: { sOpenId, dateIndex: this.dateIndex },
      });
    },
    ExportInfo() {
      this.show = true;
    },
    quxiao() {
      this.show = false;
    },
    queren() {
      if (this.sEndtime == "") {
        Toast({
          message: "请先选择日期区间",
          duration: 2000,
        });
      } else {
        this.show = false;
        let params = {
          sStartTime: this.sStartTime,
          sEndtime: this.sEndtime,
          doctorId: window.localStorage.getItem("doctorId"),
        };
        ExportInfo(params)
          .then((res) => {
            if (res.data.IsSuccess) {
              console.log(res.data.Data.url);
              // window.open(res.data.Data.url,'_blank')
              this.excelShow = true;
              this.excelUrl = res.data.Data.url;
            } else {
              Toast({
                message: res.data.Message,
                duration: 2000,
              });
            }
          })
          .catch((err) => {
            Toast({
              message: err,
              duration: 2000,
            });
          });
      }
    },
    ctrlC() {
      // this.excelShow = false
      this.$copyText(this.excelUrl).then(() => {
        Toast({
          message: "链接已复制到剪切板",
          duration: 2000,
        });
      })
      .catch(() => {
        Toast({
          message: "复制失败",
          duration: 2000,
        });
      });
    },
    sTimePopup() {
      this.dateShow = true;
    },
    datePopup(date) {
      this.dateShow = false;
      // console.log(date)
      this.sStartTime = getNowDay(date[0]);
      this.sEndtime = getNowDay(date[1]);
    },
    pushMy() {
      this.$router.push({
        path: `/my`,
        query: {},
      });
    },
    // 切换页面数据
    // prevClick(page) {
    //   console.log("上一页", page);
    // },
    // nextClick(page) {
    //   console.log("下一页", page);
    // },
    // pageClick(page) {
    //   console.log("分页点击", page);
    // },
    pageJumpe(page) {
      console.log("跳转", page);
    },
    // prevQuickClick(page) {
    //   console.log("快速向前切换", page);
    // },
    // nextQuickClick(page) {
    //   console.log("快速向后切换", page);
    // },
    currentChange(page, old) {
      console.log("分页切换", page, "切换前分页", old);
      this.pageIndex = page;
      this.getList();
    },
    timeClick(){
      this.timeShow = true
    },
    timePopup(date){
      // console.log(date)
      this.timeShow = false
      this.date = getNowDay(date)
      this.dateIndex = -getDateDifference(date)
      this.getList();
    }
  },
};
</script>
<style lang='scss' scoped>
.home {
  width: 100vw;
  height: 100vh;
  background: #dff2fc;

  .title {
    width: 100%;
    height: 1.1rem;
    background: url(../assets/bg_listTitle.png) no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      width: 0.03rem;
      height: 0.03rem;
      border-radius: 50%;
      background: #fff;
      margin: 0 0.02rem 0.12rem 0.02rem;

      &.opacity {
        opacity: 0.55;
      }
    }

    p {
      color: #fff;
      font-size: 0.18rem;
      margin: 0 0.13rem 0.12rem 0.13rem;
      text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
      font-weight: 400;
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 1.57rem);
    position: relative;

    .timePicker {
      z-index: 2;
      width: 3.16rem;
      height: 0.35rem;
      background: #fff;
      border-radius: 0.1rem;
      position: absolute;
      left: 0.3rem;
      top: -0.35rem;
      box-shadow: 0 0 0.07rem 0.01rem rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.14rem;

      .btn {
        flex: 0.32;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        img {
          width: 0.08rem;
          height: 0.14rem;
        }
      }

      .line {
        width: 0.01rem;
        height: 0.25rem;
      }

      .date {
        font-size: 0.13rem;
        flex: 0.32;
        color: #007cc3;
      }
    }

    .tag {
      z-index: 3;
      width: 0.78rem;
      height: 0.22rem;
      background: linear-gradient(-45deg, #ff7098, #ff9365);
      border-top-left-radius: 0.11rem;
      border-bottom-left-radius: 0.11rem;
      position: absolute;
      right: 0;
      top: 0.08rem;

      p {
        color: #fff;
        font-size: 0.12rem;
        line-height: 0.22rem;
      }
    }

    .listbox {
      z-index: 1;
      width: calc(100% - 0.28rem);
      height: calc(100% + 0.08rem);
      background: #fff;
      border-radius: 0.1rem;
      position: absolute;
      left: 0.14rem;
      top: -0.18rem;
      box-shadow: 0 0 0.07rem 0.01rem rgba(0, 0, 0, 0.3);
      padding: 0 0.14rem;
      box-sizing: border-box;

      .search {
        width: 3.2rem;
        height: 0.35rem;
        background: url(../assets/bg_search.png) no-repeat;
        background-size: 100% 100%;
        padding: 0.05rem;
        margin-top: 0.54rem;
        box-sizing: border-box;
        display: flex;
        justify-content: center;

        input {
          width: 2.8rem;
          height: 0.25rem;
          border-radius: 0.25rem;
          padding: 0 0.15rem;
          box-sizing: border-box;
          font-size: 0.14rem;
        }

        .btn {
          flex: 1;
        }
      }

      .list {
        width: 100%;
        height: auto;
        margin: 0.1rem auto 0.05rem auto;

        .th {
          background: #54c3f1;
          width: 100%;
          height: 0.36rem;
          border-radius: 0.05rem;
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            font-size: 0.12rem;
            color: #fff;

            &:nth-of-type(1) {
              flex: 0.2;
            }

            &:nth-of-type(2) {
              flex: 0.165;
            }

            &:nth-of-type(3) {
              flex: 0.165;
            }

            &:nth-of-type(4) {
              flex: 0.27;
            }

            &:nth-of-type(5) {
              flex: 0.2;
            }
          }
        }

        .td {
          width: 100%;
          height: 0.34rem;
          border-bottom: 0.01rem solid #c0c0c0;
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            font-size: 0.11rem;
            color: #333;

            &:nth-of-type(1) {
              flex: 0.2;
            }

            &:nth-of-type(2) {
              flex: 0.165;
            }

            &:nth-of-type(3) {
              flex: 0.165;
            }

            &:nth-of-type(4) {
              flex: 0.27;
            }

            &:nth-of-type(5) {
              flex: 0.2;
              color: #007cc3;
            }
          }
        }
      }
    }
  }

  .not_data_img {
    width: 1.5rem;
    height: auto;
    margin: 0.6rem auto 0 auto;
  }
  .not_data_title {
    font-size: 0.15rem;
    text-align: center;
  }

  .footbox {
    width: 100%;
    height: 0.47rem;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 0.05rem 0.01rem rgba(0, 0, 0, 0.08);

    .btnbox {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .img {
        margin: 0.02rem 0;
      }

      p {
        font-size: 0.1rem;
        color: #474747;
      }
    }
  }

  .poput {
    z-index: 9999;
    width: 3.13rem;
    height: 1.83rem;
    background: #fff;
    border-radius: 0.08rem;

    .box {
      width: 100%;
      height: 100%;
      position: relative;
      padding: 0.16rem 0.12rem 0.14rem 0.12rem;
      box-sizing: border-box;

      .boxtitle {
        position: absolute;
        top: 0.02rem;
        left: 0.07rem;
        width: 1.16rem;
        height: 0.25rem;
        background: url(../assets/bg_poput.png) no-repeat;
        background-size: 100% 100%;

        p {
          line-height: 0.25rem;
          text-align: center;
          color: #fff;
          font-size: 0.12rem;
        }
      }

      .borderdiv {
        width: 100%;
        height: 100%;
        border: 0.01rem solid #babbbb;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .inputBox {
          width: 100%;
          height: 0.3rem;
          display: flex;
          justify-content: center;
          align-items: center;
          &:nth-of-type(1) {
            margin-top: 0.3rem;
          }
          p {
            font-size: 0.14rem;
          }
          input {
            width: 1.34rem;
            height: 0.2rem;
            border: 0.01rem solid #2fa8df;
            padding: 0.01rem 0.03rem;
            border-radius: 0.03rem;
            font-size: 0.14rem;
          }
        }
        .btnbox {
          width: 100%;
          height: 0.5rem;
          margin-top: 0.2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          .queren,
          .quxiao {
            width: 0.9rem;
            height: 0.28rem;
            margin: 0 0.12rem;
            font-size: 0.14rem;
            line-height: 0.26rem;
            color: #fff;
          }
          .queren {
            background: url(../assets/bg_yzmBtn.png) no-repeat;
            background-size: 100% 100%;
          }
          .quxiao {
            background: url(../assets/bg_quxiaoBtn.png) no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
  }
}
</style>
