<template>
  <div class="home">
    <div class="title">
      <span class="opacity"></span><span></span>
      <p>{{ title }}</p>
      <span></span><span class="opacity"></span>
    </div>
    <div class="content">
      <div class="timePicker">
        <div class="btn" @click="prevDate">
          <img src="../assets/icon_l.png" />
          <p>前一日</p>
        </div>
        <img class="line" src="../assets/icon_line.png" />
        <div class="date" @click="timeClick">{{ date }}</div>
        <img class="line" src="../assets/icon_line.png" />
        <div class="btn" @click="nextDate">
          <p>后一日</p>
          <img src="../assets/icon_r.png" />
        </div>
      </div>
      <div class="listBox">
        <div class="row">
          <div class="left"><img src="../assets/icon_zongliang.png"><p>总量</p></div>
          <div class="right"><p>磷：{{totalInfo.p}}mg；蛋白：{{totalInfo.pro}}mg；磷/蛋白：{{totalInfo.p_pro}}</p></div>
        </div>
        <div class="row">
          <div class="left"><img src="../assets/icon_zaocan.png"><p>早餐</p></div>
          <div class="right"><p>磷：{{breakfast.p}}mg；蛋白：{{breakfast.pro}}mg；磷/蛋白：{{breakfast.p_pro}}</p></div>
        </div>
        <div class="row">
          <div class="left"><img src="../assets/icon_wucan.png"><p>午餐</p></div>
          <div class="right"><p>磷：{{lunch.p}}mg；蛋白：{{lunch.pro}}mg；磷/蛋白：{{lunch.p_pro}}</p></div>
        </div>
        <div class="row">
          <div class="left"><img src="../assets/icon_wancan.png"><p>晚餐</p></div>
          <div class="right"><p>磷：{{dinner.p}}mg；蛋白：{{dinner.pro}}mg；磷/蛋白：{{dinner.p_pro}}</p></div>
        </div>
        <div class="row">
          <div class="left"><img src="../assets/icon_qita.png"><p>其他</p></div>
          <div class="right"><p>磷：{{other.p}}mg；蛋白：{{other.pro}}mg；磷/蛋白：{{other.p_pro}}</p></div>
        </div>
        <div class="MWjunzhi">
          <div class="boxstyle boxW">
            <div class="left">
              <p>近一周均值</p>
              <span>{{weekdate}}</span>
              <span>至</span>
              <span>{{yesterday}}</span>
            </div>
            <div class="right">
              <p class="p">磷<br>{{week.p}}</p>
              <div class="p_pro"><p>磷/蛋白<br>{{week.p_pro}}</p></div>
              <p class="pro">蛋白<br>{{week.pro}}</p>
            </div>
          </div>
          <div class="boxstyle boxM">
            <div class="left">
              <p>近一月均值</p>
              <span>{{monthdate}}</span>
              <span>至</span>
              <span>{{yesterday}}</span>
            </div>
            <div class="right">
              <p class="p">磷<br>{{month.p}}</p>
              <div class="p_pro"><p>磷/蛋白<br>{{month.p_pro}}</p></div>
              <p class="pro">蛋白<br>{{month.pro}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footbox">
      <div class="btnbox" @click="pushList"><img class="img" style="width:0.16rem;height:0.18rem" src="../assets/icon_home.png"><p>主页</p></div>
      <div class="btnbox" @click="pushMy"><img class="img" style="width: 0.18rem; height: 0.2rem" src="../assets/icon_my.png"/><p>我的</p></div>
    </div>
    <van-calendar v-model:show="timeShow" :show-confirm="false" :min-date="minDate" :max-date="maxDate" @confirm="timePopup"/>
  </div>
</template>

<script>
import { fun_date, getNowDay, getDateDifference } from "../util/util";
import { Toast } from 'vant'
import { GetPatientDietInfo } from '../api/doctorApi.js'
export default {
  name: "patientDiet",
  data() {
    return {
      title: this.$route.meta.title,
      date:Number(this.$route.query.dateIndex)==0?fun_date(Number(this.$route.query.dateIndex)-1)[1]:fun_date(Number(this.$route.query.dateIndex))[1],
      yesterday:fun_date(Number(this.$route.query.dateIndex)-1)[1],
      weekdate:'',
      monthdate:'',
      dateIndex:Number(this.$route.query.dateIndex)==0?Number(this.$route.query.dateIndex)-1:Number(this.$route.query.dateIndex),
      totalInfo:'', //总量
      breakfast:'', //早餐
      lunch:'', //午餐
      dinner:'', //晚餐
      other:'', //其他
      week:'', //周平均
      month:'', //月平均
      timeShow:false,
      minDate: "",
      maxDate: "",
    };
  },
  beforeCreate() {
    document.title = this.$route.meta.title;
    if(window.localStorage.getItem('doctorId')==null){
      Toast({
        message: '请重新登录',
        duration: 1500,
      });
      this.$router.push({
        path: `/login`,
        query: {}
      })
    }
  },
  created() {
    this.minDate = new Date(fun_date(-730)[1]);
    this.maxDate = new Date(fun_date(-1)[1]);
  },
  computed: {},
  mounted() {
    this.getList()
    this.date = fun_date(Number(this.$route.query.dateIndex))[1]
    this.yesterday = fun_date(Number(this.$route.query.dateIndex))[1]
    this.weekdate = fun_date(this.$route.query.dateIndex-7)[1]
    this.monthdate = fun_date(this.$route.query.dateIndex-30)[1]
  },
  methods: {
    getList(){
      let params = {
        sDate:this.date,
        sOpenId:this.$route.query.sOpenId
      }
      GetPatientDietInfo(params).then((res) => {
        if (res.data.IsSuccess) {
          // this.navItems = res.data.items.map(i => i.sHospitalName)
          if(res.data.Data.totalInfo==null){
            this.totalInfo = {p: 0,p_pro: 0,pro: 0}
          }else{
            this.totalInfo = res.data.Data.totalInfo
          }
          
          if(res.data.Data.breakfast==null){
            this.breakfast = {p: 0,p_pro: 0,pro: 0}
          }else{
            this.breakfast = res.data.Data.breakfast
          }

          if(res.data.Data.lunch==null){
            this.lunch = {p: 0,p_pro: 0,pro: 0}
          }else{
            this.lunch = res.data.Data.lunch
          }
          
          if(res.data.Data.dinner==null){
            this.dinner = {p: 0,p_pro: 0,pro: 0}
          }else{
            this.dinner = res.data.Data.dinner
          }
          
          if(res.data.Data.other==null){
            this.other = {p: 0,p_pro: 0,pro: 0}
          }else{
            this.other = res.data.Data.other
          }

          if(res.data.Data.week==null){
            this.week = {p: 0,p_pro: 0,pro: 0}
          }else{
            this.week = res.data.Data.week
          }

          if(res.data.Data.month==null){
            this.month = {p: 0,p_pro: 0,pro: 0}
          }else{
            this.month = res.data.Data.month
          }
        }else{
          Toast({
            message: res.data.Message,
            duration: 2000,
          });
        }
      }).catch((err) => {
        Toast({
          message: err,
          duration: 2000,
        });
      })
    },
    prevDate(){
      this.dateIndex = this.dateIndex-1
      this.date = fun_date(this.dateIndex)[1]
      this.pageIndex = 1
      this.getList()
    },
    nextDate(){
      if(this.dateIndex>=-1){
        Toast({
          message: '最多只能查询到昨天患者信息',
          duration: 2500,
        });
      }else{
        this.dateIndex = this.dateIndex+1
        this.date = fun_date(this.dateIndex)[1]
        this.pageIndex = 1
        this.getList()
      }
    },
    pushList(){
      this.$router.push({
        path: `/patientList`,
        query: {}
      })
    },
    pushMy(){
      this.$router.push({
        path: `/my`,
        query: {}
      })
    },
    timeClick(){
      this.timeShow = true
    },
    timePopup(date){
      // console.log(date)
      this.timeShow = false
      this.date = getNowDay(date)
      this.dateIndex = -getDateDifference(date)
      this.getList()
    }
  },
};
</script>
<style lang='scss' scoped>
.home {
  width: 100vw;
  height: 100vh;
  background: #dff2fc;

  .title {
    width: 100%;
    height: 1.1rem;
    background: url(../assets/bg_listTitle.png) no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      width: 0.03rem;
      height: 0.03rem;
      border-radius: 50%;
      background: #fff;
      margin: 0 0.02rem 0.12rem 0.02rem;

      &.opacity {
        opacity: 0.55;
      }
    }

    p {
      color: #fff;
      font-size: 0.18rem;
      margin: 0 0.13rem 0.12rem 0.13rem;
      text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
      font-weight: 400;
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 1.57rem);
    position: relative;
    background:#fff;

    .timePicker {
      z-index: 2;
      width: 3.5rem;
      height: 0.4rem;
      background: #fff;
      border-radius: 0.1rem;
      position: absolute;
      left: 0.12rem;
      top: -0.2rem;
      box-shadow: 0 0 0.07rem 0.01rem rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.14rem;

      .btn {
        flex: 0.32;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        img {
          width: 0.08rem;
          height: 0.14rem;
        }
      }

      .line {
        width: 0.01rem;
        height: 0.25rem;
      }

      .date {
        font-size: 0.15rem;
        flex: 0.388;
        color: #007cc3;
      }
    }

    .listBox{
      padding-top: 0.56rem;
      .row{
        width: 100%;
        height: 0.46rem;
        border-top: 0.08rem solid #f7f7f7;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0.16rem;
        box-sizing: border-box;
        .left{
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          img{
            width: 0.28rem;
            height: 0.28rem;
            margin-right: 0.1rem;
          }
          p{
            font-size: 0.15rem;
            font-weight: bold;
          }
        }
        .right{
          height: 100%;
          p{
            font-size: 0.12rem;
            line-height: 0.4rem;
          }
        }
      }
    }
    .MWjunzhi{
      border-top: 0.1rem solid #f7f7f7;
      width: 100%;
      height: auto;
      .boxstyle{
        width: 3.5rem;
        height: 0.85rem;
        border-radius: 0.1rem;
        box-shadow: 0.04rem 0 0.08rem 0.01rem rgba(0,0,0,0.1);
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        .left{
          width: 0.8rem;
          height: 100%;
          color:#fff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          p{
            font-size: 0.12rem;
            margin-bottom: 0.06rem;
          }
          span{
            font-size: 0.1rem;
          }
        }
        .right{
          flex: 1;
          height: 100%;
          position: relative;
          .p{
            position: absolute;
            bottom: 0;
            left: 50%;
            margin-left: -0.87rem;
            font-size: 0.12rem;
            color:#00a5dc
          }
          .p_pro{
            position: absolute;
            top: 0;
            left: 50%;
            margin-left: -0.435rem;
            width: 0.87rem;
            height: 100%;
            background:url(../assets/bg_lindanbai.png) center center no-repeat;
            background-size: auto 95%;
            p{
              margin-top: 0.2rem;
              font-size: 0.12rem;
              margin-left:-0.04rem;
            }
          }
          .pro{
            position: absolute;
            bottom: 0;
            left: 50%;
            margin-left: 0.57rem;
            font-size: 0.12rem;
            color:#a8cd80
          }
        }
      }
      .boxW{
        margin: 0.12rem 0 0.18rem 0.14rem;
        .left{
          background:#00a5dc;
        }
      }
      .boxM{
        margin-left: 0.14rem;
        .left{
          background:#a8cd80;
        }
      }
    }
  }

  .footbox {
    width: 100%;
    height: 0.47rem;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 0.05rem 0.01rem rgba(0, 0, 0, 0.08);

    .btnbox {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .img {
        margin: 0.02rem 0;
      }

      p {
        font-size: 0.1rem;
        color: #474747;
      }
    }
  }
}
</style>
