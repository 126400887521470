<template>
  <div class="home">
    <div class="title">
      <span class="opacity"></span><span></span><p>{{title}}</p><span></span><span class="opacity"></span>
    </div>
    <div class="erweima">
      <p class="p">医生二维码</p>
      <img :src="qrCode">
      <p class="span">患者使用微信扫码，绑定医生所在医院科室</p>
    </div>
    <div class="content" @click="logout">
      <img src="../assets/icon_chilun.png">
      <p>退出登录</p>
      <img src="../assets/icon_r.png">
    </div>
    <div class="footbox">
      <div class="btnbox" @click="pushList"><img class="img" style="width:0.16rem;height:0.18rem" src="../assets/icon_home.png"><p>主页</p></div>
      <!-- <div class="btnbox"><img class="img" style="width:0.18rem;height:0.20rem" src="../assets/icon_my.png"><p>我的</p></div> -->
      <div class="btnbox"><img class="img" style="width:0.34rem;height:0.34rem" src="../assets/icon_my_select.png"></div>
    </div>
    
  </div>
</template>

<script>
import { Toast } from 'vant'
import { DoctorLoginOut } from '../api/doctorApi';
export default {
  name: 'myC',
  data() {
    return {
      title:this.$route.meta.title,
      qrCode:window.localStorage.getItem('sQrCodeUrl')
    }
  },
  components:{
    
  },
  beforeCreate () {
    document.title = this.$route.meta.title
    if(window.localStorage.getItem('doctorId')==null){
      Toast({
        message: '请重新登录',
        duration: 1500,
      });
      this.$router.push({
        path: `/login`,
        query: {}
      })
    }
  },
  created() {

  },
  computed: {
    
  },
  mounted() {

  },
  methods: {
    pushList(){
      this.$router.push({
        path: `/patientList`,
        query: {}
      })
    },
    logout(){
      DoctorLoginOut({doctorId:window.localStorage.getItem('doctorId')}).then((res) => {
        if (res.data.IsSuccess) {
          localStorage.clear()
          this.$router.push({
            path: `/login`,
            query: {}
          })
        }else{
          Toast({
            message: res.data.Message,
            duration: 2000,
          });
        }
      }).catch((err) => {
        Toast({
          message: err,
          duration: 2000,
        });
      })
    }
  }
}
</script>
<style lang='scss' scoped>
  .home{
    width: 100vw;
    height: 100vh;
    background:url(../assets/bg.png) no-repeat;
    background-size: 100% 100%;
    .title{
      width:100%;
      height: 1.1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      span{
        width: 0.03rem;
        height: 0.03rem;
        border-radius: 50%;
        background:#fff;
        margin:0 0.02rem 0.12rem 0.02rem;
        &.opacity{
          opacity: 0.55;
        }
      }
      p{
        color:#fff;
        font-size: 0.18rem;
        margin: 0 0.13rem 0.12rem 0.13rem;
        text-shadow: 1px 1px 3px rgba(0,0,0,0.3);
        font-weight: 400;
      }
    }
    .erweima{
      width: 2.8rem;
      height: 2.4rem;
      // background:#dff2fc;
      border-radius: 0.08rem;
      margin: 0.4rem auto 0.8rem auto;
      .p{
        font-size: 0.15rem;
        color:#fff;
        text-align: center;
        font-weight: bold;
        line-height: 0.16rem;
        margin-bottom: 0.15rem;
      }
      img{
        width: 2.1rem;
        height: auto;
      }
      .span{
        font-size: 0.12rem;
        color:#fff;
        text-align: center;
        line-height: 0.23rem;
      }
    }
    .content{
      height: 0.65rem;
      width: 3.5rem;
      background:#fff;
      border-radius: 0.1rem;
      box-shadow: 0 0.01rem 0.1rem 0 rgba(76,76,76,0.69);
      margin: 0 auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      img{
        margin:0 0.2rem;
        &:nth-of-type(1){
          width: 0.2rem;
          height: 0.2rem;
        }
        &:nth-of-type(2){
          width: 0.07rem;
          height: 0.12rem;
          margin-left: auto;
        }
      }
      p{
        font-size: 0.16rem;
      }
    }
    .footbox{
      width: 100%;
      height: 0.47rem;
      background:#fff;
      position: fixed;
      bottom: 0;
      left:0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0 0 0.05rem 0.01rem rgba(0,0,0,0.08);
      .btnbox{
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .img{
          margin: 0.02rem 0;
        }
        p{
          font-size: 0.1rem;
          color:#474747;
        }
      }
    }
    
    
  }
</style>