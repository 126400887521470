<template>
	<img class="bg" src="../assets/bg.png" alt="">
	<div class="home">
		<img class="logo" src="../assets/logo_linshi.png" alt="">
		<div class="inputBox">
			<input type="number" v-model="sTel" placeholder="请输入手机号">
			<div class="yzmBtn" v-if="yzmShow" @click="getCode">验证码</div>
			<div class="yzmBtn" v-if="!yzmShow">{{yzm}}</div>
		</div>
		<div class="inputBox">
			<input type="password" v-model="code" placeholder="请输入验证码">
		</div>
		<div class="inputBox">
			<input type="password" v-model="pwd" placeholder="请设置密码">
		</div>
		<div class="inputBox">
			<input type="text" v-model="sHospital" @click="hospitalShow" readonly placeholder="请选择医院">
		</div>
		<div class="inputBox">
			<input type="text" v-model="sDepartment" @click="departmentShow" readonly placeholder="请选择科室">
		</div>
		<div class="btn" @click="nextView">注册</div>
		<div class="tips" @click="nextLogin">如已有账号，点击此处跳转登录页</div>
	</div>
	<!-- 弹框 -->
	<van-popup position="bottom" v-model:show="show" :style="{ height: '60%' }">
		<van-picker
			title="标题"
			:columns="searchColumns"  
			@confirm="pickerConfirm"
			@cancel="show = false"
		>
		<template #title>
      <van-field
				:style="{ width: '60%'}"
        v-model="searchKey"
        placeholder="此处可手动搜索相应内容"
        clearable
      />
      </template>
		</van-picker>
	</van-popup>
</template>

<script>
	import {
		Toast,Dialog
	} from 'vant'
	import {
		SendSMSCode,
		DoctorRegister,
		GetAllDepartment,
		GetAllHospital
	} from '../api/doctorApi';
	export default {
		name: 'registerView',
		data() {
			return {
				sTel: '',
				code: '',
				pwd: '',
				sDepartment:'',
				sHospital:'',
				yzm: '',
				times: 60,
				yzmShow: true,
				show:false,
				columns: [],
				searchColumns: [],
				searchKey:'',
				status:''
			}
		},
		beforeCreate() {
			document.title = this.$route.meta.title
		},
		created() {

		},
		computed: {

		},
		mounted() {

		},
		methods: {
			getCode() {
				if (this.sTel == '') {
					Toast({
						message: '请输入手机号',
						duration: 1500,
					});
					return false
				}
				let myreg = /^(0|86|17951)?(13[0-9]|14[5,7]|15[^4]|16[6]|17[0,1,3,5,6,7,8]|18[0-9]|19[8,9])[0-9]{8}$/;
				if (!myreg.test(this.sTel)) {
					Toast({
						message: '对不起，您输入的手机格式不正确',
						duration: 1500,
					});
					return false
				}

				SendSMSCode({
					sTel: this.sTel
				}).then((res) => {
					console.log(res)
					Toast({
						message: res.data.Message,
						duration: 1500,
					});
				}).catch((err) => {
					console.log(err);
					Toast({
						message: err,
						duration: 1500,
					});
				})
				this.timer = setInterval(() => {
					this.yzmShow = false
					this.times--
					this.yzm = this.times + 's'
					if (this.times === 0) {
						this.yzmShow = true
						this.yzm = '验证码'
						this.times = 60
						clearInterval(this.timer)
					}
				}, 1000)
			},
			nextView() {
				if (this.sTel == '') {
					Toast({
						message: '请输入手机号',
						duration: 1500,
					});
					return false
				}
				let myreg = /^(0|86|17951)?(13[0-9]|14[5,7]|15[^4]|16[6]|17[0,1,3,5,6,7,8]|18[0-9]|19[0-9])[0-9]{8}$/;
				if (!myreg.test(this.sTel)) {
					Toast({
						message: '对不起，您输入的手机格式不正确',
						duration: 1500,
					});
					return false
				}
				if (this.code == '') {
					Toast({
						message: '请输入验证码',
						duration: 1500,
					});
					return false
				}
				if(this.sHospital == ''){
					Toast({
						message: '请填写医院名称',
						duration: 1500,
					});
					return false
				}
				if(this.sDepartment == ''){
					Toast({
						message: '请填写科室名称',
						duration: 1500,
					});
					return false
				}
				let params = {
					sTel: this.sTel,
					code: this.code,
					pwd: this.pwd,
					sHospital: this.sHospital,
					sDepartment: this.sDepartment
				}
				DoctorRegister(params).then((res) => {
					if (res.data.IsSuccess) {
						Dialog.alert({
							message: '账号注册成功，点击跳转登录',
						}).then(() => {
							this.$router.push({
								path: `/login`,
								query: {}
							})
						});
					}else{
						Toast({
							message: res.data.Message,
							duration: 2000,
						});
					}
				}).catch((err) => {
					Toast({
						message: err,
						duration: 2000,
					});
				})
			},
			nextLogin() {
				this.$router.push({
					path: `/login`,
					query: {}
				})
			},
			hospitalShow(){
				this.show = true
				this.status = 'hospital'
				this.searchKey = ''
				let params = {
					sName: this.searchKey
				}
				GetAllHospital(params).then((res) => {
					if (res.data.IsSuccess) {
						this.columns = res.data.Data.hospitals.map( item => item.sName),
						this.searchColumns = res.data.Data.hospitals.map( item => item.sName)
					}else{
						Toast({
							message: res.data.Message,
							duration: 2000,
						});
					}
				}).catch((err) => {
					Toast({
						message: err,
						duration: 2000,
					});
				})
			},
			departmentShow(){
				this.show = true
				this.status = 'department'
				this.searchKey = ''
				let params = {
					sName: this.searchKey
				}
				GetAllDepartment(params).then((res) => {
					if (res.data.IsSuccess) {
						this.columns = res.data.Data.departments.map( item => item.sName),
						this.searchColumns = res.data.Data.departments.map( item => item.sName)
					}else{
						Toast({
							message: res.data.Message,
							duration: 2000,
						});
					}
				}).catch((err) => {
					Toast({
						message: err,
						duration: 2000,
					});
				})
			},
			pickerConfirm(val){
				this.show = false
				if(val == undefined){
					if(this.status == 'hospital'){
						Toast({
							message: '必须选择一家医院',
							duration: 2000,
						});
					}else if(this.status == 'department'){
						Toast({
							message: '必须选择一个科室',
							duration: 2000,
						});
					}
				}
				if(this.status == 'hospital'){
					this.sHospital = val
				}else if(this.status == 'department'){
					this.sDepartment = val
				}
			},
		},
		watch: {  //实时监听搜索输入内容
			searchKey: function () {
				let key = String( this.searchKey );
				key =  key.replace( /\s*/g, "" );   //去除搜索内容中的空格
				
				/* 进行筛选，将筛选后的数据放入新的数组中*/
				// this.searchColumns = this.columns.filter( item => item.match( key ) !=null ); 
				if(this.status == 'hospital'){
					let params = {
						sName: key
					}
					GetAllHospital(params).then((res) => {
						if (res.data.IsSuccess) {
							this.columns = res.data.Data.hospitals.map( item => item.sName),
							this.searchColumns = res.data.Data.hospitals.map( item => item.sName)
						}else{
							Toast({
								message: res.data.Message,
								duration: 2000,
							});
						}
					}).catch((err) => {
						Toast({
							message: err,
							duration: 2000,
						});
					})
				}else if(this.status == 'department'){
					let params = {
						sName: key
					}
					GetAllDepartment(params).then((res) => {
						if (res.data.IsSuccess) {
							this.columns = res.data.Data.departments.map( item => item.sName),
							this.searchColumns = res.data.Data.departments.map( item => item.sName)
						}else{
							Toast({
								message: res.data.Message,
								duration: 2000,
							});
						}
					}).catch((err) => {
						Toast({
							message: err,
							duration: 2000,
						});
					})
				}
			}
		}
	}
</script>
<style lang='scss' scoped>
	.bg {
		width: 100%;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1;
	}
	.home {
		position: fixed;
		z-index: 2;
		width: 100vw;
		// height: 100vh;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.logo {
			width: 2.82rem;
			height: 2.78rem;
			margin: 0.2rem auto;
		}

		.inputBox {
			width: 2.82rem;
			height: 0.36rem;
			background: #ededed;
			border-radius: 10px;
			overflow: hidden;
			margin: 0 auto 0.1rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			&:nth-of-type(1){
				input{
					width: 60%;
				}
			}
			input {
				width: 90%;
				height: 100%;
				padding: 0 10px 0 20px;
				box-sizing: border-box;
				text-align: left;
				background: #ededed;
				font-size: 0.14rem;
			}

			.yzmBtn {
				color: #fff;
				font-size: 0.12rem;
				width: 0.78rem;
				height: 0.29rem;
				text-align: center;
				line-height: 0.29rem;
				background: url(../assets/bg_yzmBtn.png) no-repeat;
				background-size: 100% 100%;
				margin: 2px 10px 0 0;
			}
		}

		.btn {
			background: url(../assets/bg_btn.png);
			background-size: 100% 100%;
			width: 1.08rem;
			height: 0.35rem;
			font-size: 0.16rem;
			text-align: center;
			line-height: 0.35rem;
			color: #666;
			margin: 0.2rem auto;
		}

		.tips {
			width: 90%;
			margin: 0 auto;
			color: #fff;
			font-size: 0.12rem;
			text-align: center;
			text-decoration:underline
		}
	} 
	::v-deep .van-field__control{
		text-align: center;
	}
</style>
