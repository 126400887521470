import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import loginView from '../views/login.vue'
import registerView from '../views/register'
import patientList from '../views/patientList'
import patientDiet from '../views/patientDiet'
import myC from '../views/my'
import changePwd from '../views/changePwd'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: '磷食记录'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: loginView,
    meta: {
      title: '登录'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: registerView,
    meta: {
      title: '注册'
    }
  },
  {
    path: '/patientList',
    name: 'patientList',
    component: patientList,
    meta: {
      title: '患者列表'
    }
  },
  {
    path: '/patientDiet',
    name: 'patientDiet',
    component: patientDiet,
    meta: {
      title: '近期饮食数据'
    }
  },
  {
    path: '/my',
    name: 'my',
    component: myC,
    meta: {
      title: '个人'
    }
  },
  {
    path: '/changePwd',
    name: 'changePwd',
    component: changePwd,
    meta: {
      title: '修改密码'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
