<template>
	<div class="home">
		<div class="inputBox">
			<input type="text" v-model="sTel" placeholder="请输入手机号">
			<div class="yzmBtn" v-if="yzmShow" @click="getCode">验证码</div>
			<div class="yzmBtn" v-if="!yzmShow">{{yzm}}</div>
		</div>
		<div class="inputBox">
			<input type="password" v-model="code" placeholder="请输入验证码">
		</div>
		<div class="inputBox">
			<input type="password" v-model="pwd" placeholder="请输入新密码">
		</div>
		<div class="btn" @click="nextView">确认</div>
	</div>
</template>

<script>
	import {
		Toast,Dialog
	} from 'vant'
	import {
		SendSMSCode,
		DoctorRegister
	} from '../api/doctorApi';
	export default {
		name: 'changePwd',
		data() {
			return {
				sTel: '',
				code: '',
				pwd: '',
				sDepartment:'',
				sHospital:'',
				yzm: '',
				times: 60,
				yzmShow: true,
			}
		},
		beforeCreate() {
			document.title = this.$route.meta.title
		},
		created() {

		},
		computed: {

		},
		mounted() {

		},
		methods: {
			getCode() {
				if (this.sTel == '') {
					Toast({
						message: '请输入手机号',
						duration: 1500,
					});
					return false
				}
				let myreg = /^(0|86|17951)?(13[0-9]|14[5,7]|15[^4]|16[6]|17[0,1,3,5,6,7,8]|18[0-9]|19[8,9])[0-9]{8}$/;
				if (!myreg.test(this.sTel)) {
					Toast({
						message: '对不起，您输入的手机格式不正确',
						duration: 1500,
					});
					return false
				}

				SendSMSCode({
					sTel: this.sTel
				}).then((res) => {
					console.log(res)
					Toast({
						message: res.data.Message,
						duration: 1500,
					});
				}).catch((err) => {
					console.log(err);
					Toast({
						message: err,
						duration: 1500,
					});
				})
				this.timer = setInterval(() => {
					this.yzmShow = false
					this.times--
					this.yzm = this.times + 's'
					if (this.times === 0) {
						this.yzmShow = true
						this.yzm = '验证码'
						this.times = 60
						clearInterval(this.timer)
					}
				}, 1000)
			},
			nextView() {
				if (this.sTel == '') {
					Toast({
						message: '请输入手机号',
						duration: 1500,
					});
					return false
				}
				let myreg = /^(0|86|17951)?(13[0-9]|14[5,7]|15[^4]|16[6]|17[0,1,3,5,6,7,8]|18[0-9]|19[0-9])[0-9]{8}$/;
				if (!myreg.test(this.sTel)) {
					Toast({
						message: '对不起，您输入的手机格式不正确',
						duration: 1500,
					});
					return false
				}
				if (this.code == '') {
					Toast({
						message: '请输入验证码',
						duration: 1500,
					});
					return false
				}
				let params = {
					sTel: this.sTel,
					code: this.code,
					pwd: this.pwd
				}
				DoctorRegister(params).then((res) => {
					if (res.data.IsSuccess) {
						Dialog.alert({
							message: '账号注册成功，点击跳转登录',
						}).then(() => {
							this.$router.push({
								path: `/login`,
								query: {}
							})
						});
					}else{
						Toast({
							message: res.data.Message,
							duration: 2000,
						});
					}
				}).catch((err) => {
					Toast({
						message: err,
						duration: 2000,
					});
				})
			},
			nextLogin() {
				this.$router.push({
					path: `/login`,
					query: {}
				})
			}
		}
	}
</script>
<style lang='scss' scoped>
	.home {
		width: 100vw;
		height: 100vh;
		background: url(../assets/bg.png) no-repeat;
		background-size: 100% 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.logo {
			width: 2.82rem;
			height: 2.78rem;
			margin: 0.2rem auto;
		}

		.inputBox {
			width: 2.82rem;
			height: 0.36rem;
			background: #ededed;
			border-radius: 10px;
			overflow: hidden;
			margin: 0 auto 0.2rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			&:nth-of-type(1){
				margin-top: 1rem;
			}
			input {
				width: 60%;
				height: 100%;
				padding: 0 10px 0 20px;
				box-sizing: border-box;
				text-align: left;
				background: #ededed;
				font-size: 0.14rem;
			}

			.yzmBtn {
				color: #fff;
				font-size: 0.12rem;
				width: 0.78rem;
				height: 0.29rem;
				text-align: center;
				line-height: 0.29rem;
				background: url(../assets/bg_yzmBtn.png) no-repeat;
				background-size: 100% 100%;
				margin: 2px 10px 0 0;
			}
		}

		.btn {
			background: url(../assets/bg_btn.png);
			background-size: 100% 100%;
			width: 1.08rem;
			height: 0.35rem;
			font-size: 0.16rem;
			text-align: center;
			line-height: 0.35rem;
			color: #666;
			margin: 0.2rem auto;
		}

		.tips {
			width: 90%;
			margin: 0 auto;
			color: #fff;
			font-size: 0.12rem;
			text-align: center;
			text-decoration:underline
		}
	}
</style>
