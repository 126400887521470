// 导入vue及vuex
import { createApp } from 'vue'
import Vuex from 'vuex'
// 挂载vuex
createApp().use(Vuex)
// 创建vuex对象并向外暴露
const store =  new Vuex.Store({
  // 全局属性变量
  state: {
    // baseURL: 'http://examtest.cicayun.com',  //测试
    baseURL: 'https://www.my-hos.com:3020', //正式
    // imgURL: 'http://examtest.cicayun.com', //测试
    // imgURL: 'http://examtest.cicayun.com', //正式
  },
  // 全局同步方法, 调用方法,this.$store.commit("xxx")
  mutations: {
    changeSubmitData(state,params){  
      state.healthCardSubmitData = params
    },
  },
  // 异步方法 调用方法,this.$store.dispatch("xxx") 
  actions: {
    
  },
  // Vuex属性计算,在视图里面当变量使用
  getters: {
  },
  // 模块化注册
  modules: {
  }
})

export default store