<template>
	<img class="bg" src="../assets/bg.png" alt="">
	<img class="logo" src="../assets/logo_linshi.png" alt="">
	<div class="home" :style="topHeight">
		<div class="inputBox">
			<input type="number" v-model="sTel" @blur="animateHeight('blur')" @focus="animateHeight('focus')" placeholder="请输入手机号">
		</div>
		<div class="inputBox">
			<input type="password" v-model="sPwd" @blur="animateHeight('blur')" @focus="animateHeight('focus')" placeholder="请输入密码">
		</div>
		<div class="btn" @click="nextView">登录</div>
		<div class="tips" @click="nextRegister">如未注册账号，点击此处跳转注册页</div>
	</div>
</template>

<script>
	import { Toast } from 'vant'
	import { DoctorLogin } from '../api/doctorApi';
	export default {
		name: 'loginView',
		data() {
			return {
				sTel: '',
				sPwd: '',
				topHeight:'top:4.18rem;'
			}
		},
		beforeCreate() {
			document.title = this.$route.meta.title
		},
		created() {

		},
		computed: {

		},
		mounted() {

		},
		methods: {
			nextView() {
				let params = {
					sTel: this.sTel,
					sPwd: this.sPwd,
				}
				DoctorLogin(params).then((res) => {
					if (res.data.IsSuccess) {
						// localStorage.setItem("userInfo",JSON.stringify(res.data.Data))
						localStorage.setItem("token",res.data.Data.sToken)
						localStorage.setItem("doctorId",res.data.Data.id)
						localStorage.setItem("sQrCodeUrl",res.data.Data.sQrCodeUrl)
						localStorage.setItem("sName",res.data.Data.sName)
						this.$router.push({
							path: `/patientList`,
							query: {}
						})
					}else{
						Toast({
							message: res.data.Message,
							duration: 2000,
						});
					}
				}).catch((err) => {
					Toast({
						message: err,
						duration: 2000,
					});
				})
			},
			nextRegister() {
				this.$router.push({
					path: `/register`,
					query: {}
				})
			},
			animateHeight(type){
				this.topHeight = (type=="focus"?'top:2.18rem;':'top:4.18rem;')
			}
		}
	}
</script>
<style lang='scss' scoped>
	.bg {
		width: 100%;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1;
	}
	.logo {
		position: fixed;
		width: 2.82rem;
		height: 2.78rem;
		top:1rem;
		left:0.465rem;
		z-index: 2;
	}
	.home {
		position: absolute;
		z-index: 3;
		width: 100vw;
		// height: 100vh;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.inputBox {
			width: 2.82rem;
			height: 0.36rem;
			background: #ededed;
			border-radius: 10px;
			overflow: hidden;
			margin: 0 auto 0.2rem;
			display: flex;
			justify-content: center;
			align-items: center;

			input {
				width: 100%;
				height: 100%;
				padding: 0 10px;
				box-sizing: border-box;
				text-align: center;
				background: #ededed;
				font-size: 0.14rem;
			}
		}

		.btn {
			background: url(../assets/bg_btn.png);
			background-size: 100% 100%;
			width: 1.08rem;
			height: 0.35rem;
			font-size: 0.16rem;
			text-align: center;
			line-height: 0.35rem;
			color: #666;
			margin: 0.2rem auto;
		}

		.tips {
			width: 90%;
			margin: 0 auto;
			color: #fff;
			font-size: 0.12rem;
			text-align: center;
			text-decoration:underline
		}
	}
</style>
