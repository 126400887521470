import axios from 'axios'
import store from '@/store'
// import { getToken } from './token'


const tokenHeaderName = 'CKDtoken'
const baseURL = store.state.baseURL
const service = axios.create({
  baseURL, // url = base url + request url
  timeout: 30000 // request timeout
})
// request interceptor
service.interceptors.request.use(
  config => {
    if(window.localStorage.getItem('token')!==null){
      config.headers[tokenHeaderName] = window.localStorage.getItem('token')
    }else{
      config.headers[tokenHeaderName] = ''
    }
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor


export default service
