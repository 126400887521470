/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */

//当天日期+num天
let fun_date = (num) =>{ 
  var date1 = new Date();
  //今天时间
  var time1 = `${date1.getFullYear()}-${date1.getMonth() + 1}-${date1.getDate()}`
  var date2 = new Date(date1);
  date2.setDate(date1.getDate() + num);
  //num是正数表示之后的时间，num负数表示之前的时间，0表示今天
  var time2 = `${(date2.getMonth() + 1)<10?`0${date2.getMonth() + 1}`:(date2.getMonth() + 1)}.${date2.getDate()<10?`0${date2.getDate()}`:date2.getDate()}`; 
  var time3 = `${date2.getFullYear()}-${(date2.getMonth() + 1)<10?`0${date2.getMonth() + 1}`:(date2.getMonth() + 1)}-${date2.getDate()<10?`0${date2.getDate()}`:date2.getDate()}`; 
  return [time2,time3];
}

//当前日期
let getNowDay = (dateValue) => {
  if(dateValue==null){
    var date = new Date();
    var y = date.getFullYear(); 
    var m = date.getMonth()+1; 
    var d = date.getDate();
    return `${y}-${m<10?`0${m}`:m}-${d<10?`0${d}`:d}`
  }else{
    var date = new Date(dateValue);
    var y = date.getFullYear(); 
    var m = date.getMonth()+1; 
    var d = date.getDate();
    return `${y}-${m<10?`0${m}`:m}-${d<10?`0${d}`:d}`
  }
}

//传入日期距当天相差的天数
let getDateDifference = (startDate) => {
  const sDate = Date.parse(startDate);
  const eDate = new Date();
  if (sDate > eDate) {
      return 0
  }
  if (sDate === eDate) {
      return 1
  }
  const days = (eDate - sDate) / (1 * 24 * 60 * 60 * 1000)
  return parseInt(days)
}

export {
  getNowDay, // 当前日期
  fun_date, //当天日期+num天
  getDateDifference //传入日期距当天相差的天数
}