import request from '../util/request';

// 获取注册登录验证码信息
const SendSMSCode = data => {
	return request({
		url: '/api/DoctorAPI/SendSMSCode',
		method: 'post',
		data
	})
}

//注册
const DoctorRegister = data => {
	return request({
		url: '/api/DoctorAPI/DoctorRegister',
		method: 'post',
		data
	})
}

//登录
const DoctorLogin = data => {
	return request({
		url: '/api/DoctorAPI/DoctorLogin',
		method: 'post',
		data
	})
}

//退出登录
const DoctorLoginOut = data => {
	return request({
		url: '/api/DoctorAPI/DoctorLoginOut',
		method: 'post',
		data
	})
}

//患者列表
const GetPaitentList = params => {
	return request({
		url: `/api/DoctorAPI/GetPaitentList`,
		method: 'get',
		params
	})
}

//患者列表导出信息
const ExportInfo = params => {
	return request({
		url: `/api/DoctorAPI/ExportInfo`,
		method: 'get',
		params
	})
}

//获取患者个人某天磷蛋统计信息
const GetPatientDietInfo = params => {
	return request({
		url: `/api/DoctorAPI/GetPatientDietInfo`,
		method: 'get',
		params
	})
}

//获取医院信息
const GetAllHospital = params =>{
	return request({
		url:`/api/DoctorAPI/GetAllHospital`,
		method:'get',
		params
	})
}

//获取科室信息
const GetAllDepartment = params =>{
	return request({
		url:`/api/DoctorAPI/GetAllDepartment`,
		method:'get',
		params
	})
}

export {
	SendSMSCode,
	DoctorRegister,
	DoctorLogin,
	DoctorLoginOut,
	GetPaitentList,
	ExportInfo,
	GetPatientDietInfo,
	GetAllDepartment,
	GetAllHospital
}
